import { Pressable } from 'react-native';
import { useLocation, useNavigate } from 'react-router-dom';
import { FeatureFlags } from '@lawnstarter/customer-modules/enums';
import { useFeatureFlag } from '@lawnstarter/customer-modules/hooks';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';

import { HEADER_ROUTE_OPTIONS } from '@src/constants';
import { shouldHideFooterInCurrentRoute } from '@src/layout/FooterNavbar/helpers.ts';

import { StyledFooter } from './styles';

import type { CurrentTabProps } from '@src/types';

export const useShouldHideMobileFooter = (): boolean => {
  const location = useLocation();

  const isRefinedLawnTreatmentFlowWebEnabled = useFeatureFlag(
    FeatureFlags.ALLOW_REFINED_LAWN_TREATMENT_FLOW_WEB,
  );

  if (isRefinedLawnTreatmentFlowWebEnabled && shouldHideFooterInCurrentRoute(location)) {
    return true;
  }
  return false;
};

export function FooterNavbar({ currentTab }: CurrentTabProps) {
  const navigate = useNavigate();
  const theme = useAppTheme();
  const shouldHideFooter = useShouldHideMobileFooter();

  if (shouldHideFooter) {
    return null;
  }

  return (
    // switched to px because it was not showing up with the temp literal of theme size.
    <StyledFooter style={{ boxShadow: `0 0 4px ${theme.colors.gray.secondary}` }}>
      {HEADER_ROUTE_OPTIONS.map(({ link, label, icon, tab }) => {
        const itemColor = {
          color: currentTab === tab ? theme.colors.primary : theme.colors.gray.primary,
        };

        return (
          <Pressable key={label} onPress={() => navigate(link)}>
            <div className="flex flex-col justify-center items-center">
              <Icon name={icon as any} size={theme.sizing.s7} color={itemColor.color} />
              <Text variant="bodyMedium" style={itemColor}>
                {label}
              </Text>
            </div>
          </Pressable>
        );
      })}
    </StyledFooter>
  );
}
