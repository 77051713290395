import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  QuoteMulchingOptions,
  SCHEDULE_CYCLE_MONTHLY,
} from '@lawnstarter/customer-modules/constants';
import { InstantQuoteTypes, WebRoutes } from '@lawnstarter/customer-modules/enums';
import { getMulchingBedFieldData, parseFlowerBeds } from '@lawnstarter/customer-modules/helpers';
import { useOrderServiceViewedTracking } from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  instantQuote_acceptQuote,
  instantQuote_generateQuote,
  instantQuote_rejectQuote,
  properties_currentPropertyIdSelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking, useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { GeneratedInstantQuoteViewer, OrderServiceHeader } from '@src/components';
import { FlowerBedWeedingForm } from '@src/components/organisms/forms';
import { scrollToBottom } from '@src/helpers';
import {
  useAutoScrollOnFormErrors,
  useDispatch,
  useRouteNavigation,
  useSelector,
} from '@src/hooks';

import { StyledContainer, StyledFormContainer } from './styles';

import type { FlowerBedWeedingFormData } from '@src/components/organisms/forms';

export function OrderFlowerBedWeedingScreen() {
  const dispatch = useDispatch();
  const { navigate } = useRouteNavigation();
  const rejectModal = useModal(ModalTemplate.FEEDBACK);

  const instantQuote = useSelector((state) => state.instantQuote);
  const propertyId = useSelector(properties_currentPropertyIdSelector);
  const [showQuote, setShowQuote] = useState(false);

  const apiTracking = useApiTracking()!;
  useOrderServiceViewedTracking(apiTracking, ServiceType.FlowerBedWeeding);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, submitCount },
    unregister,
  } = useForm<FlowerBedWeedingFormData>({
    defaultValues: {
      cycle: SCHEDULE_CYCLE_MONTHLY,
    },
    shouldUnregister: false,
  });

  useAutoScrollOnFormErrors({ errors, submitCount });

  const submit = async ({
    desired_handling,
    desired_haulaway,
    cycle,
    details,
    images,
    ...dynamicFields
  }: FlowerBedWeedingFormData) => {
    const pendingQuote = {
      desired_handling,
      desired_haulaway,
      cycle,
      details,
      flower_beds: parseFlowerBeds(dynamicFields),
      pendingImages: images,
    };

    const result = await dispatch(
      instantQuote_generateQuote({
        property_id: propertyId,
        type: InstantQuoteTypes.FlowerBedWeeding,
        pendingQuote,
      }),
    );

    setShowQuote(!!result?.success);
  };

  const approve = async () => {
    const result = await dispatch(instantQuote_acceptQuote({ property_id: propertyId }));

    if (result?.success) {
      navigate(WebRoutes.services, { options: { replace: true } });
    }
  };

  const reject = () => {
    rejectModal.show({
      isAnswerRequired: false,
      origin: 'instant_quote_flower_bed_weeding_form',
      title: t('instantQuote.viewer.rejectFeedback.title'),
      question: t('instantQuote.viewer.rejectFeedback.reason'),
      questionPlaceholder: t('forms.enterDetailsHere'),
      buttonText: t('instantQuote.viewer.rejectButton'),
      onSubmit: onSubmitReject,
    });
  };

  const onSubmitReject = async ({ answer }: { answer?: string }) => {
    const result = await dispatch(
      instantQuote_rejectQuote({
        property_id: propertyId,
        formData: {
          acceptable_price: 0,
          notes: _.isEmpty(answer) ? null : answer,
        },
      }),
    );

    if (result?.success) {
      navigate(WebRoutes.browseRedirect, { options: { replace: true } });
    }
  };

  useEffect(() => {
    showQuote && scrollToBottom();
  }, [showQuote]);

  return (
    <StyledContainer>
      <OrderServiceHeader
        className="max-md:mb-2 md:mb-4"
        section={t('instantQuoteFor')}
        serviceName={t('order.flowerBedWeeding.title')}
      />
      <StyledFormContainer>
        <FlowerBedWeedingForm
          control={control}
          setValue={setValue}
          unregister={unregister}
          minBeds={1}
          maxBeds={20}
          mulchingOptions={QuoteMulchingOptions}
          mulchingBedOptions={getMulchingBedFieldData()}
        />
        {!showQuote && (
          <Button
            mode="contained"
            trackID="instant_quote_leaf_removal_form-generate_quote"
            loading={instantQuote?.loadingStatus.isLoading}
            onPress={handleSubmit(submit)}
          >
            {t('generateQuote')}
          </Button>
        )}
        {showQuote && instantQuote?.currentQuote && (
          <div className="mt-4">
            <GeneratedInstantQuoteViewer
              property_id={Number(propertyId)}
              currentQuote={instantQuote!.currentQuote}
              onApprove={approve}
              onReject={reject}
              isUpdating={instantQuote!.loadingStatus.isUpdating}
            />
          </div>
        )}
      </StyledFormContainer>
    </StyledContainer>
  );
}
