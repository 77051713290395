import { useCallback } from 'react';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { CancellationDeflection } from '@lawnstarter/ls-react-common/enums';

import {
  CancellationDeflectionChangePro,
  CancellationDeflectionMoving,
  CancellationDeflectionPause,
  CancellationDeflectionSupport,
} from '@src/components/templates';
import { useRouteNavigation } from '@src/hooks';

import type { DeflectionElementProps } from './types';

export function DeflectionElement({ deflection, onNextStep, params }: DeflectionElementProps) {
  const { navigate } = useRouteNavigation();

  const onPrimaryPress = useCallback(
    (destination: WebRoutes) => () => navigate(destination, { params }),
    [navigate, params],
  );

  switch (deflection) {
    case CancellationDeflection.ChangePro:
      return (
        <CancellationDeflectionChangePro
          onPrimaryPress={onPrimaryPress(WebRoutes.changeMyPro)}
          onSecondaryPress={onNextStep}
        />
      );

    case CancellationDeflection.ContactSupport:
      return (
        <CancellationDeflectionSupport
          onPrimaryPress={onPrimaryPress(WebRoutes.supportFromCancel)}
          onSecondaryPress={onNextStep}
        />
      );

    case CancellationDeflection.Pause:
      return (
        <CancellationDeflectionPause
          onPrimaryPress={onPrimaryPress(WebRoutes.pauseService)}
          onSecondaryPress={onNextStep}
        />
      );

    default:
      return <CancellationDeflectionMoving onPrimaryPress={onNextStep} />;
  }
}
