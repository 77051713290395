import { useMemo, useState } from 'react';
import { Image, ImageSourcePropType, Pressable, View } from 'react-native';
import { useParams } from 'react-router-dom';
import LawnTreatmentMonth1Photo from '@lawnstarter/customer-modules/assets/services/lawn_treatment_month_1.png';
import LawnTreatmentMonth5Photo from '@lawnstarter/customer-modules/assets/services/lawn_treatment_month_5.png';
import LawnTreatmentMonth11Photo from '@lawnstarter/customer-modules/assets/services/lawn_treatment_month_11.png';
import { LTRS_FAQ_ITEMS, LTRS_FAQ_ITEMS_LEGACY } from '@lawnstarter/customer-modules/constants';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import { useFeatureFlag, useOrderServiceViewedTracking } from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  auth_signedUserSelector,
  orderServices_orderPricedLtrsService,
} from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking, useModal } from '@lawnstarter/ls-react-common/hooks';
import { Faq, Question } from '@lawnstarter/ls-react-common/molecules';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import {
  AboutLawnTreatment,
  AboutLawnTreatmentLegacy,
  HelperBottomSheet,
  ScheduleLawnTreatment,
} from '@src/components';
import { PlanDetailsTable } from '@src/components/molecules/Table/PlanDetailsTable.tsx';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';

import { MobileOrderLawnTreatmentTemplate } from './MobileOrderLawnTreatmentTemplate';
import { DesktopOnlyWrapper, MobileOnlyWrapper } from './MobileStyles';
import {
  OrderLawnTreatmentContainer,
  StyledFaqContainer,
  StyledOrderLawnTreatmentContainer,
  StyledOrderLawnTreatmentInfoContainer,
  styles,
} from './styles';

export function OrderLawnTreatmentScreen() {
  const [showSchedule, setShowSchedule] = useState(false);
  const { email: customerEmail } = useSelector(auth_signedUserSelector);
  const { propertyId, servicePromoId } = useParams();
  const isUpdating = useSelector(({ orderServices }) => orderServices.loadingStatus.isUpdating);
  const modal = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const dispatch = useDispatch();
  const { navigate } = useRouteNavigation();

  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const lawnTreatmentInfo = useSelector(
    ({ properties }) =>
      properties.propertiesById[propertyId as string]?.priced?.services?.fertilization,
  );

  const isRefinedLawnTreatmentFlowWebEnabled = useFeatureFlag(
    FeatureFlags.ALLOW_REFINED_LAWN_TREATMENT_FLOW_WEB,
  );

  const apiTracking = useApiTracking()!;
  useOrderServiceViewedTracking(apiTracking, ServiceType.LawnTreatment);

  const onSubmit = async () => {
    const { success, error } = await dispatch(
      orderServices_orderPricedLtrsService({
        property_id: propertyId,
        service_promo_id: servicePromoId,
        service_id: lawnTreatmentInfo?.service.id,
        price_id: lawnTreatmentInfo?.options[0].price_id,
      }),
    );

    if (success) {
      const message = t('confirmationServiceOrder.message', {
        customerEmail,
        serviceName: lawnTreatmentInfo?.service.name,
      });

      modal.show({
        icon: 'checkbox-marked-circle-plus-outline',
        action: t('confirmationServiceOrder.title'),
        messages: [message],
        buttonText: t('goToMyServices'),
        onDismiss: () => navigate(WebRoutes.services, { options: { replace: true } }),
      });
    } else {
      modal.show({
        icon: 'block-helper',
        action: t('somethingWentWrong'),
        messages: [error?.error?.message ?? t('apiGenericError')],
        buttonText: t('tryAgain'),
      });
    }
  };

  const renderLegacyLawnTreatmentComponents = () => {
    if (showSchedule) {
      return (
        <ScheduleLawnTreatment
          price={lawnTreatmentInfo?.options[0].price ?? ''}
          submitRequest={onSubmit}
          isLoading={isUpdating}
        />
      );
    }

    return (
      <AboutLawnTreatmentLegacy
        showSchedule={() => {
          setShowSchedule(!showSchedule);
        }}
      />
    );
  };

  const renderOrderLawnTreatmentContainer = () => {
    return (
      <StyledOrderLawnTreatmentInfoContainer>
        <Pressable
          accessibilityLabel={t('goBack')}
          onPress={() => navigate(WebRoutes.back)}
          style={style.goBackIconContainer}
        >
          <Icon name="arrow-left" size={theme.sizing.s6} style={style.goBackIcon} />
        </Pressable>

        <OrderLawnTreatmentContainer style={style.orderLawnTreatmentContainer}>
          <Text variant="headlineLarge">{t('ltrs.orderLawnTreatment.title')}</Text>

          <br />
          <View style={{ marginBottom: 16 }} />

          <Text variant="labelLarge" style={style.orderLawnTreatmentSubtitle}>
            {t('ltrs.orderLawnTreatment.subtitle')}
          </Text>

          <View style={{ marginBottom: 12 }} />

          <Text variant="labelLarge" style={style.orderLawnTreatmentDescription}>
            {t('ltrs.orderLawnTreatment.description')}
          </Text>

          <View style={style.orderLawnTreatmentItems}>
            <Icon name="card-search-outline" size={theme.sizing.s6} />
            <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
              {t('ltrs.orderLawnTreatment.items.certifiedPro')}
            </Text>
          </View>

          <View style={style.orderLawnTreatmentItems}>
            <Icon name="star-circle-outline" size={theme.sizing.s6} />
            <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
              {t('ltrs.orderLawnTreatment.items.guaranteedFreeTouchUps')}
            </Text>
          </View>

          <View style={style.orderLawnTreatmentItems}>
            <Icon name="bone" size={theme.sizing.s6} />
            <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
              {t('ltrs.orderLawnTreatment.items.safeForPetsAndFamilies')}
            </Text>
          </View>

          <View>
            <Text variant="bodyLarge" style={style.planDetailsTitle} allowFontScaling={false}>
              {t('ltrs.orderLawnTreatment.planDetails')}
            </Text>
          </View>
          <PlanDetailsTable />

          <View style={style.imagesContainer}>
            <View style={style.smallImagesContainer}>
              <Image
                source={LawnTreatmentMonth1Photo as ImageSourcePropType}
                style={style.smallImage}
              />
              <Text variant="labelMedium" style={style.smallImageSubtitle}>
                {t('ltrs.orderLawnTreatment.imageSubtitles.monthOne')}
              </Text>
            </View>
            <View style={style.smallImagesContainer}>
              <Image
                source={LawnTreatmentMonth5Photo as ImageSourcePropType}
                style={style.smallImage}
              />
              <Text variant="labelMedium" style={style.smallImageSubtitle}>
                {t('ltrs.orderLawnTreatment.imageSubtitles.monthFive')}
              </Text>
            </View>
          </View>

          <View style={style.largeImagesContainer}>
            <Image
              source={LawnTreatmentMonth11Photo as ImageSourcePropType}
              style={style.largeImage}
            />
            <Text variant="labelMedium" style={style.largeImageSubtitle}>
              {t('ltrs.orderLawnTreatment.imageSubtitles.monthEleven')}
            </Text>
            <Text variant="bodySmall" style={style.footer}>
              {t('ltrs.orderLawnTreatment.footer')}
            </Text>
          </View>
        </OrderLawnTreatmentContainer>
      </StyledOrderLawnTreatmentInfoContainer>
    );
  };

  const renderLegacyOrderLawnTreatmentContainer = () => {
    return (
      <StyledFaqContainer>
        <Faq header={t('customerFaq.faq')} style={{ wrapperScroll: { height: 300 } }}>
          {
            LTRS_FAQ_ITEMS_LEGACY.map((item, index) => (
              <Question key={`faq:${index}`} question={item.title} trackID="" onPress={() => {}}>
                <Text style={style.faqInfo}>{item.content}</Text>
              </Question>
            )) as any
          }
        </Faq>
      </StyledFaqContainer>
    );
  };

  const renderLawnTreatmentComponents = () => {
    return (
      <AboutLawnTreatment
        onPress={() => {
          navigate(WebRoutes.confirmLawnTreatment, {
            params: {
              propertyId: propertyId as string,
            },
          });
        }}
        price={lawnTreatmentInfo?.options[0].price ?? ''}
        isLoading={isUpdating}
        shouldOrder={false}
      />
    );
  };

  const renderFAQContent = () =>
    LTRS_FAQ_ITEMS.map((item) => (
      <Question
        key={item.title}
        question={item.title}
        trackID="about_lawn_treatment-ltrs_faq_items"
      >
        <Text
          style={{
            width: 320,
            marginTop: theme.spacing.s3,
            marginLeft: theme.spacing.s1,
          }}
        >
          {item.content}
        </Text>
      </Question>
    ));

  const renderSheetTitle = () => (
    <Text
      variant="bodyMedium"
      style={{
        fontSize: theme.fonts.headlineSmall.fontSize,
        fontFamily: theme.fontVariant.primary.w600,
        fontWeight: '600',
        marginLeft: theme.spacing.s1,
      }}
    >
      {t('ltrs.faq.bottomSheetTitle')}
    </Text>
  );

  if (isRefinedLawnTreatmentFlowWebEnabled) {
    return (
      <>
        <MobileOnlyWrapper>
          <MobileOrderLawnTreatmentTemplate />
          <HelperBottomSheet content={renderFAQContent()} title={renderSheetTitle()} />
        </MobileOnlyWrapper>
        <DesktopOnlyWrapper>
          <StyledOrderLawnTreatmentContainer>
            {renderLawnTreatmentComponents()}
            {renderOrderLawnTreatmentContainer()}
          </StyledOrderLawnTreatmentContainer>
        </DesktopOnlyWrapper>
      </>
    );
  }

  return (
    <StyledOrderLawnTreatmentContainer>
      {renderLegacyLawnTreatmentComponents()}
      {renderLegacyOrderLawnTreatmentContainer()}
    </StyledOrderLawnTreatmentContainer>
  );
}
