import { t } from '@lawnstarter/customer-modules/services';
import { TitleHighlighted } from '@lawnstarter/ls-react-common/molecules';
import { Confirmation, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import imageURL from '@src/assets/man-lawn-mowing.svg';

import type { Props } from './types';

export function CancellationDeflectionChangePro(props: Props) {
  const buttons = [
    {
      label: t('yesChangeMyPro'),
      onPress: props.onPrimaryPress,
    },
    {
      label: t('cancelMyService'),
      mode: 'text' as const,
      onPress: props.onSecondaryPress,
    },
  ];

  const description = [
    t('cancelService.deflection.changePro.description.line1'),
    t('cancelService.deflection.changePro.description.line2'),
  ];

  const image = (
    <img alt="" data-testid="image" height={176} loading="lazy" src={imageURL} width={161} />
  );

  const title = (
    <TitleHighlighted
      style={{ justifyContent: 'center' }}
      contents={[
        {
          text: t('cancelService.deflection.changePro.title.switchTo'),
        },
        {
          text: t('cancelService.deflection.changePro.title.topPerforming'),
          highlight: true,
        },
        {
          text: t('cancelService.deflection.changePro.title.pro'),
        },
      ]}
    />
  );

  return (
    <ModalTemplate>
      <Confirmation
        buttons={buttons}
        description={description}
        descriptionStyle={{ textAlign: 'center', width: '100%' }}
        icon={image}
        origin="cancellation-deflection-change-pro"
        stacked="always"
        title={title}
      />
    </ModalTemplate>
  );
}
