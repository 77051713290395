import { useForm } from 'react-hook-form';
import { QuoteMulchingOptions } from '@lawnstarter/customer-modules/constants';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import {
  getMulchingBedFieldData,
  getMulchingFieldData,
  MQHowQuotingWorksModal,
  MQSubmitSuccessModal,
  parseFlowerBeds,
} from '@lawnstarter/customer-modules/helpers';
import { useOrderServiceViewedTracking } from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  orderServices_submitQuote,
  properties_currentPropertyIdSelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking, useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate, MulchingForm } from '@lawnstarter/ls-react-common/organisms';

import { OrderServiceHeader } from '@src/components';
import {
  useAutoScrollOnFormErrors,
  useDispatch,
  useRouteNavigation,
  useSelector,
} from '@src/hooks';

import { StyledOrderWrapper } from './styles';

import type { MulchingFormData } from '@lawnstarter/ls-react-common/organisms';

export function OrderMulchingScreen() {
  const dispatch = useDispatch();
  const { navigate } = useRouteNavigation();
  const propertyId = useSelector(properties_currentPropertyIdSelector);
  const isUpdating = useSelector(({ orderServices }) => orderServices.loadingStatus.isUpdating);

  const MQStepsModal = useModal(ModalTemplate.STEPS);
  const QuoteSubmittedModal = useModal(ModalTemplate.ACTION_CONFIRMATION);

  const apiTracking = useApiTracking()!;
  useOrderServiceViewedTracking(apiTracking, ServiceType.Mulching);

  const {
    control,
    setValue,
    unregister,
    handleSubmit,
    formState: { errors, submitCount },
  } = useForm<MulchingFormData>({
    defaultValues: {
      desired_sheeting: 'fabric',
      desired_type: 'wood',
      desired_type_other_details: '',
      desired_color: 'black',
      remove_mulch: false,
      haul_mulch: false,
      self_purchase_materials: false,
      details: '',
      budget: '',
      pendingImages: [],
    },
  });

  useAutoScrollOnFormErrors({ errors, submitCount });

  const submit = async ({
    budget,
    details,
    haul_mulch,
    desired_type,
    remove_mulch,
    pendingImages,
    desired_color,
    desired_sheeting,
    self_purchase_materials,
    desired_type_other_details,
    ...dynamicFields
  }: MulchingFormData) => {
    const result = await dispatch(
      orderServices_submitQuote({
        updatedFields: {
          service_id: 6,
          pendingImages,
          property_id: propertyId,
          service_str_id: 'mulching',
          budget: parseFloat(budget.toString()).toFixed(2),
          mulching_additional: {
            remove_mulch,
            desired_type,
            details: details,
            desired_color: desired_color,
            desired_sheeting: desired_sheeting,
            flower_beds: parseFlowerBeds(dynamicFields),
            self_purchase_materials: self_purchase_materials,
            ...(remove_mulch && { haul_mulch: haul_mulch }),
            ...(desired_type === 'other' && { desired_type_other_details }),
          },
        },
      }),
    );

    if (result?.success) {
      QuoteSubmittedModal.show(MQSubmitSuccessModal(navigate, WebRoutes.services));
    }
  };

  return (
    <StyledOrderWrapper>
      <OrderServiceHeader section={t('forms.quote.request')} serviceName={t('mulching.service')} />

      <form>
        <div className="my-4 gap-4 flex flex-col">
          <MulchingForm
            control={control}
            isUpdating={isUpdating}
            fieldData={getMulchingFieldData()}
            maxBeds={20}
            minBeds={1}
            setValue={setValue}
            unregister={unregister}
            mulchingOptions={QuoteMulchingOptions}
            mulchingBedOptions={getMulchingBedFieldData()}
            infoModal={() => MQStepsModal.show(MQHowQuotingWorksModal())}
          />
        </div>

        <Button
          mode="contained"
          loading={isUpdating}
          onPress={handleSubmit(submit)}
          trackID="browse-screen_order-mulching-submit-btn"
        >
          {t('forms.getPrice')}
        </Button>
      </form>
    </StyledOrderWrapper>
  );
}
